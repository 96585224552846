import axios from "axios";
// require("dotenv").config({ path: "../../.env" });

const env = process.env.NODE_ENV;

const instance = axios.create({
  baseURL:
    env === "production"
      ? "https://gb-backend.onrender.com/" // production
      : "http://localhost:5000/", // development
});

export default instance;
